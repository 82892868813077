<template>
	<!-- nav -->
	<nav class="nav nav--fit">
		<div class="nav__wrap">
			<a class="nav__btn nav__btn--prev">
			</a>
			<p class="nav__item">
				<span class="nav__text">
					픽
				</span>
			</p>
			<a class="nav__btn nav__btn--map">
			</a>
		</div>
	</nav>
	<!-- //nav-->
	<div id="container" class="container container--fit">
		<div id="sub" class="sub pick">
			<!-- content -->
			<section class="content">
				<div class="row">
					<div class="centered centered--large">
						<div class="centered__wrap">
							<div class="textbox">
								<h3 class="textbox__title">
									새로운 숙소를 <br>
									픽 해보세요
								</h3>
								<p class="textbox__description">
									더욱 빠르게 관심 숙소를 만날 수 있어요!
								</p>
								<div class="textbox__button">
									<button class="btn btn-primary purple" type="button">새로운 숙소 보러가기</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<!-- //content -->
		</div>
	</div>
	<TheNavigation></TheNavigation>
</template>